/**
 * Testimonial slider script
 */
 class SS_Testimonials {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState () {
      this.testimonialsListWrapper = $('.testimonials-list-wrapper');
      this.dotsList = this.testimonialsListWrapper.find('.slider-dots');
      this.dots = this.dotsList.find('.dot');
      this.list = this.testimonialsListWrapper.find('.testimonials-list');
      this.items = this.list.find('.testimonials-item');
      this.currentItem = 0;
      this.itemsPerPage = 3;
      this.animationInProgress = false;
  }

  initEvents() {
      this.initRWD();
  
      if (this.testimonialsListWrapper.length) {
        this.initUI();
      }
    }

  initUI() {
    this.swipeEffect = new Hammer(this.testimonialsListWrapper[0]);

    for (let i = 0; i < this.dots.length; i++) {
      this.dots.eq(i).on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem = i;
        this.animationInProgress = true;
        this.dots.removeClass('active');
        this.dots.eq(this.currentItem).addClass('active');
        this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
        
        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });
    }

    this.swipeEffect.on('swipe', ev => {
      if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
        if (this.currentItem < this.items.length - this.itemsPerPage) {
          this.currentItem++;
        } else {
          return
        }

        this.dots.eq(this.currentItem).trigger('click');
      }

      if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
        if (this.currentItem > 0) {
          this.currentItem--;
        } else {
          return
        }

        this.dots.eq(this.currentItem).trigger('click');
      }
    });
  }

  initRWD () {
    this.desktopViewport = window.matchMedia('(min-width: 841px)');
    this.tabletViewport = window.matchMedia('(min-width: 561px) and (max-width: 840px)');
    this.mobileViewport = window.matchMedia('(max-width: 560px)');
    if (this.desktopViewport.matches) this.updateViewports('desktop');
    if (this.tabletViewport.matches) this.updateViewports('tablet');
    if (this.mobileViewport.matches) this.updateViewports('mobila');
    this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
    this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
    this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);


    if (this.desktopViewport.matches) {
      this.itemsPerPage = 3;
    } else if (this.tabletViewport.matches) {
      this.itemsPerPage = 2;
    } else if (this.mobileViewport.matches) {
      this.itemsPerPage = 1;
    }

    this.toggleDotsVisibility();

    this.desktopViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(3);
      }
    });

    this.tabletViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(2);
      }
    });

    this.mobileViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(1);
      }
    });
  }

  updateViewports (type) {
    if (this.currentViewport !== type) {
      this.currentItem = 0;
      this.list.css('margin-left', "0%");
      this.currentViewport = type;
    }
  }
  
  setItemsPerPage (numberOfItems) {
    this.currentItem = 0;
    this.itemsPerPage = numberOfItems;
    this.dots.removeClass('active');
    this.dots.eq(this.currentItem).addClass('active');
    this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
    this.toggleDotsVisibility();    
  }

  toggleDotsVisibility () {
    let visibleDots = this.items.length - this.itemsPerPage;

    for (let i = 0; i < this.dots.length; i++) {
      let visibilityState = i <= visibleDots ? 'block' : 'none';
      this.dots.eq(i).css('display', visibilityState);
    }

    if (this.items.length - this.itemsPerPage <= 0) {
      this.dotsList.css('display', 'none');
    } else {
      this.dotsList.css('display', 'flex');
    }
  }
}