/**
 * Brands slider script
 */
 class SS_Brands {
  constructor() {
    this.initState();
    this.initEvents();
  }

  initState () {
      this.brandsListWrapper = $('.brands');
      this.brandsSlider = $('.brands-slider');
      this.dotsList = this.brandsListWrapper.find('.slider-dots');
      this.dots = this.dotsList.find('.dot');
      this.prevBtn = this.brandsListWrapper.find('.prev');
      this.nextBtn = this.brandsListWrapper.find('.next');
      this.list = this.brandsSlider.find('.brands-list');
      this.items = this.list.find('.brands-item');
      this.currentItem = 3;
      this.itemsPerPage = 5;
      this.animationInProgress = false;
  }

  initEvents() {
      this.initRWD();
  
      if (this.brandsListWrapper.length) {
        this.initUI();
      }
    }

  initUI() {
    this.swipeEffect = new Hammer(this.brandsSlider[0]);
    this.list.css('margin-left', (-100 * this.currentItem) + "%");

    this.nextBtn.on('click', (e) => {
      e.preventDefault();
      this.prevBtn.removeClass('is-hidden');

      if (this.animationInProgress) {
        return;
      }

      this.currentItem++;

      if (this.currentItem >= (this.items.length / this.itemsPerPage) - 1) {
        this.nextBtn.addClass('is-hidden');
      }

      this.animationInProgress = true;
      this.dots.removeClass('active');
      this.dots.eq(this.currentItem).addClass('active');
      this.list.css('margin-left', (-100 * this.currentItem) + "%");

      setTimeout(() => {
        this.animationInProgress = false;
      }, 550);
    });

    this.prevBtn.on('click', (e) => {
      e.preventDefault();
      this.nextBtn.removeClass('is-hidden');

      if (this.animationInProgress) {
        return;
      }

      this.currentItem--;

      if (this.currentItem <= 0) {
        this.prevBtn.addClass('is-hidden');
      }

      this.animationInProgress = true;
      this.dots.removeClass('active');
      this.dots.eq(this.currentItem).addClass('active');
      this.list.css('margin-left', (-100 * this.currentItem) + "%");

      setTimeout(() => {
        this.animationInProgress = false;
      }, 550);
    });

    for (let i = 0; i < this.dots.length; i++) {
      this.dots.eq(i).on('click', e => {
        e.preventDefault();

        if (this.animationInProgress) {
          return;
        }

        this.currentItem = i;
        this.animationInProgress = true;

        if (this.currentItem >= (this.items.length / this.itemsPerPage) - 1) {
          this.nextBtn.addClass('is-hidden');
        } else {
          this.nextBtn.removeClass('is-hidden');
        }

        if (this.currentItem <= 0) {
          this.prevBtn.addClass('is-hidden');
        } else {
          this.prevBtn.removeClass('is-hidden');
        }

        this.dots.removeClass('active');
        this.dots.eq(this.currentItem).addClass('active');
        this.list.css('margin-left', (-100 * this.currentItem) + "%");
        
        setTimeout(() => {
          this.animationInProgress = false;
        }, 550);
      });
    }

    this.swipeEffect.on('swipe', ev => {
      if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
        if (this.currentItem >= (this.items.length / this.itemsPerPage) - 1) {
          this.nextBtn.addClass('is-hidden');
          return
        } else {
          this.currentItem++;
        }

        this.dots.eq(this.currentItem).trigger('click');
      }

      if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
        if (this.currentItem > 0) {
          this.currentItem--;
        } else {
          this.prevBtn.addClass('is-hidden');
          return
        }

        this.dots.eq(this.currentItem).trigger('click');
      }
    });
  }

  initRWD () {
    this.desktopViewport = window.matchMedia('(min-width: 1041px)');
    this.desktopSmallViewport = window.matchMedia('(min-width: 721px) and (max-width: 1040px)');
    this.tabletViewport = window.matchMedia('(min-width: 561px) and (max-width: 720px)');
    this.mobileViewport = window.matchMedia('(max-width: 560px)');
    if (this.desktopViewport.matches) this.updateViewports('desktop');
    if (this.desktopSmallViewport.matches) this.updateViewports('desktopSmall');
    if (this.tabletViewport.matches) this.updateViewports('tablet');
    if (this.mobileViewport.matches) this.updateViewports('mobile');
    this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
    this.desktopSmallViewport.addListener(mq => mq.matches ? this.updateViewports('desktopSmall') : false);
    this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
    this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);

    if (this.desktopViewport.matches) {
      this.itemsPerPage = 5;
    } else if (this.desktopSmallViewport.matches) {
      this.itemsPerPage = 4;
    } else if (this.tabletViewport.matches) {
      this.itemsPerPage = 3;
    } else if (this.mobileViewport.matches) {
      this.itemsPerPage = 2;
    }

    this.toggleDotsVisibility();

    this.desktopViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(5);
      }
    });

    this.desktopSmallViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(4);
      }
    });

    this.tabletViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(3);
      }
    });

    this.mobileViewport.addListener(mq => {
      if (mq.matches) {
        this.setItemsPerPage(2);
      }
    });
  }

  updateViewports (type) {
    if (this.currentViewport !== type) {
      this.currentItem = 3;
      this.list.css('margin-left', "0%");
      this.currentViewport = type;
    }
  }
  
  setItemsPerPage (numberOfItems) {
    this.currentItem = 3;
    this.itemsPerPage = numberOfItems;
    this.dots.removeClass('active');
    this.dots.eq(this.currentItem).addClass('active');
    this.list.css('margin-left', (-100 * this.currentItem) + "%");
    this.toggleDotsVisibility();    
  }

  toggleDotsVisibility () {
    let visibleDots = this.items.length / this.itemsPerPage;

    for (let i = 0; i < this.dots.length; i++) {
      let visibilityState = i <= visibleDots ? 'block' : 'none';
      this.dots.eq(i).css('display', visibilityState);
    }

    if (this.items.length - this.itemsPerPage <= 0) {
      this.dotsList.css('display', 'none');
    } else {
      this.dotsList.css('display', 'flex');
    }
  }
}