/**
 * Benefits slider script
 */
class SS_Benefits {
    constructor() {
        this.initState();
        this.initEvents();
    }

    initState() {
        this.benefitsListWrapper = $('.benefits-list-wrapper');
        this.list = this.benefitsListWrapper.find('.benefits-list');
        this.items = this.list.find('.benefits-item');
        this.prevBtn = this.benefitsListWrapper.find('.prev');
        this.nextBtn = this.benefitsListWrapper.find('.next');
        this.currentItem = 0;
        this.itemsPerPage = 5;
        this.animationInProgress = false;
    }

    initEvents() {
        this.initRWD();

        if (this.benefitsListWrapper.length) {
            this.initUI();
        }
    }

    initUI() {
        this.swipeEffect = new Hammer(this.benefitsListWrapper[0]);

        this.nextBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem++;
            this.animationInProgress = true;

            if (this.currentItem >= (this.items.length - this.itemsPerPage)) {
                this.nextBtn.addClass('is-hidden');
            }

            if (this.currentItem >= this.items.length) {
                this.currentItem = (this.items.length - 1);
            }

            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.prevBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 550);
        });

        this.prevBtn.on('click', e => {
            e.preventDefault();

            if (this.animationInProgress) {
                return;
            }

            this.currentItem--;
            this.animationInProgress = true;

            if (this.currentItem === 0) {
                this.prevBtn.addClass('is-hidden');
            }

            this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
            this.nextBtn.removeClass('is-hidden');

            setTimeout(() => {
                this.animationInProgress = false;
            }, 550);
        });


        this.swipeEffect.on('swipe', ev => {
            if (ev.type === 'swipe' && ev.direction === 2 && ev.deltaX < -30) {
                if (this.currentItem < this.dots.length - 1) {
                    this.currentItem++;
                } else {
                    this.currentItem = 0;
                }

                this.dots.eq(this.currentItem).trigger('click');
            }

            if (ev.type === 'swipe' && ev.direction === 4 && ev.deltaX > 30) {
                if (this.currentItem > 0) {
                    this.currentItem--;
                } else {
                    this.currentItem = this.dots.length - 1;
                }

                this.dots.eq(this.currentItem).trigger('click');
            }
        });
    }

    initRWD() {
        this.fullWidthViewport = window.matchMedia('(min-width: 1241px)');
        this.desktopViewport = window.matchMedia('(min-width: 1041px) and (max-width: 1240px)');
        this.tabletViewport = window.matchMedia('(min-width: 841px) and (max-width: 1040px)');
        this.bigMobileViewport = window.matchMedia('(min-width: 641px) and (max-width: 840px)');
        this.mobileViewport = window.matchMedia('(max-width: 640px)');
        if (this.fullWidthViewport.matches) this.updateViewports('fullWidth');
        if (this.desktopViewport.matches) this.updateViewports('desktop');
        if (this.tabletViewport.matches) this.updateViewports('tablet');
        if (this.bigMobileViewport.matches) this.updateViewports('bigMobile');
        if (this.mobileViewport.matches) this.updateViewports('mobile');
        this.fullWidthViewport.addListener(mq => mq.matches ? this.updateViewports('fullWidth') : false);
        this.desktopViewport.addListener(mq => mq.matches ? this.updateViewports('desktop') : false);
        this.tabletViewport.addListener(mq => mq.matches ? this.updateViewports('tablet') : false);
        this.bigMobileViewport.addListener(mq => mq.matches ? this.updateViewports('bigMobile') : false);
        this.mobileViewport.addListener(mq => mq.matches ? this.updateViewports('mobile') : false);


        if (this.fullWidthViewport.matches) {
            this.itemsPerPage = 5;
        } else if (this.desktopViewport.matches) {
            this.itemsPerPage = 4;
        } else if (this.tabletViewport.matches) {
            this.itemsPerPage = 3;
        } else if (this.bigMobileViewport.matches) {
            this.itemsPerPage = 2;
        } else if (this.mobileViewport.matches) {
            this.itemsPerPage = 1;
        }

        this.fullWidthViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(5);
            }
        });

        this.desktopViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(4);
            }
        });

        this.tabletViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(3);
            }
        });

        this.bigMobileViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(2);
            }
        });

        this.mobileViewport.addListener(mq => {
            if (mq.matches) {
                this.setItemsPerPage(1);
            }
        });
    }

    updateViewports(type) {
        if (this.currentViewport !== type) {
            this.currentItem = 0;
            this.list.css('margin-left', "0%");
            this.currentViewport = type;
        }
    }

    setItemsPerPage(numberOfItems) {
        this.currentItem = 0;
        this.itemsPerPage = numberOfItems;
        this.list.css('margin-left', ((-100 / this.itemsPerPage) * this.currentItem) + "%");
    }
}